import { FunctionComponent } from "react";
import pin from "../../assets/png/pin.png";
import graphic_preparation_img from "../../assets/png/graphic_preparation_img.png";
import { useTranslation } from "../../TranslationContext";

const GraphicPreparation: FunctionComponent = () => {
  const translations = useTranslation();
  return (
    <div
      id="graphic_preparation"
      className="relative bg-white w-full overflow-hidden flex flex-col py-24 px-24 box-border items-center justify-start text-center text-4xl text-slate-900 font-text-lg-font-normal md:px-12 sm:px-8 xs:px-4"
    >
      <div className="self-stretch flex flex-col items-center justify-start gap-10">
        <div className="w-full flex flex-col items-center justify-start gap-4 max-w-[800px]">
          <div className="self-stretch relative leading-[150%] font-extrabold">
          {translations.graphicPreparation.title}
          </div>
          <div className="self-stretch relative text-lg leading-[150%] text-slate-500">
          {translations.graphicPreparation.description}
          </div>
        </div>
        <div className="relative box-border max-w-[568px] w-3/4">
          <img
            className="relative w-full"
            alt={translations.graphicPreparation.imageAlt}
            src={graphic_preparation_img}
          />
        </div>
        <div className="w-full overflow-hidden flex flex-col items-center justify-center max-w-[1000px] text-left text-xl text-slate-950 border-solid border-indigo-200 rounded-md border-[1px] xxs:text-base">
          <div className="self-stretch h-36 flex flex-row items-start justify-start text-lg text-slate-900 lg:h-32 md:h-24 xs:text-base">
            <div className="self-stretch basis-5/12 bg-white flex flex-col py-3 px-6 items-start justify-center border-[1px] border-solid border-indigo-200 lg:px-4 sm:px-2 rounded-tl">
              <div className="flex flex-row items-start justify-start">
                <div className="relative leading-[162.5%] font-medium">
                {translations.graphicPreparation.badgeDiameter}
                </div>
              </div>
            </div>
            <div className="self-stretch flex-1 flex flex-row items-start justify-start text-xs">
              <div className="self-stretch flex-1 bg-white flex flex-col p-2.5 items-center justify-center relative gap-[3.33px] border-[1px] border-solid border-indigo-200 xl:p-1">
                <img
                  className="relative w-auto h-3/6 object-cover z-[0] lg:h-3/6"
                  alt=""
                  src={pin}
                />
                <div className="my-0 mx-[!important] absolute top-[calc(50%_-_11.08px)] left-[calc(50%_-_13.01px)] flex flex-row items-start justify-start z-[1] lg:left-[calc(50%_-_13.5px)] lg:top-[calc(38%)]">
                  <div className="relative leading-[162.5%] font-semibold  text-gradient">
                    Ø 25
                  </div>
                </div>
              </div>
              <div className="self-stretch flex-1 bg-white flex flex-col p-2.5 items-center justify-center relative gap-[3.33px] border-[1px] border-solid border-indigo-200 xl:p-1">
                <img
                  className="relative w-auto h-4/6 object-cover z-[0] lg:h-3/6"
                  alt=""
                  src={pin}
                />
                <div className="my-0 mx-[!important] absolute top-[calc(50%_-_11.08px)] left-[calc(50%_-_13.51px)] flex flex-row items-start justify-start z-[1] lg:left-[calc(50%_-_13.5px)] lg:top-[calc(38%)]">
                  <div className="relative leading-[162.5%] font-semibold  text-gradient">
                    Ø 32
                  </div>
                </div>
              </div>
              <div className="self-stretch flex-1 bg-white flex flex-col p-2.5 items-center justify-center relative gap-[3.33px] border-[1px] border-solid border-indigo-200 xl:p-1 rounded-tr">
                <img
                  className="relative w-auto h-4/5 object-cover z-[0] lg:h-4/6 xs:h-4/6 xxs:h-3/6"
                  alt=""
                  src={pin}
                />
                <div className="my-0 mx-[!important] absolute top-[calc(50%_-_11.08px)] left-[calc(50%_-_13.51px)] flex flex-row items-start justify-start z-[1] lg:left-[calc(50%_-_13.5px)] lg:top-[calc(38%)]">
                  <div className="relative leading-[162.5%] font-semibold  text-gradient">
                    Ø 50
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="self-stretch flex flex-row items-start justify-start h-24 sm:h-32">
            <div className="self-stretch basis-5/12 bg-white flex flex-col py-3 px-6 items-start justify-center gap-[8px] border-[1px] border-solid border-indigo-200 lg:px-4 sm:px-2">
              <div className="flex flex-row items-center justify-center gap-2.5 sm:gap-1">
                <div className="relative rounded-[50%] bg-cornflowerblue w-7 h-7 sm:h-5 sm:w-5" />
                <div className="relative leading-[150%]">{translations.graphicPreparation.graphics}</div>
              </div>
              <div className="self-stretch relative text-lg leading-[150%] text-slate-500 lg:text-sm">
              {translations.graphicPreparation.circleNote1}
              </div>
            </div>
            <div className="self-stretch flex-1 flex flex-row items-start justify-start text-base text-slate-900 md:text-sm">
              <div className="self-stretch flex-1 bg-white flex flex-col py-2.5 px-5 items-center justify-center border-[1px] border-solid border-indigo-200 sm:px-1">
                <div className="flex flex-row items-start justify-start gap-[3.33px]">
                  <div className="relative leading-[162.5%]">Ø 21</div>
                  <div className="relative leading-[162.5%] text-slate-500 lg:hidden">
                    mm
                  </div>
                </div>
              </div>
              <div className="self-stretch flex-1 bg-white flex flex-col py-2.5 px-5 items-center justify-center border-[1px] border-solid border-indigo-200 sm:px-1">
                <div className="flex flex-row items-start justify-start gap-[3.33px]">
                  <div className="relative leading-[162.5%]">Ø 28</div>
                  <div className="relative leading-[162.5%] text-slate-500 lg:hidden">
                    mm
                  </div>
                </div>
              </div>
              <div className="self-stretch flex-1 bg-white flex flex-col py-2.5 px-5 items-center justify-center border-[1px] border-solid border-indigo-200 sm:px-1">
                <div className="flex flex-row items-start justify-start gap-[3.33px]">
                  <div className="relative leading-[162.5%]">Ø 46</div>
                  <div className="relative leading-[162.5%] text-slate-500 lg:hidden">
                    mm
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-row items-start justify-start h-24 sm:h-32">
            <div className="self-stretch basis-5/12 bg-white flex flex-col py-3 px-6 items-start justify-center gap-[8px] border-[1px] border-solid border-indigo-200 lg:px-4 sm:px-2 rounded-bl">
              <div className="flex flex-row items-center justify-center gap-2.5 sm:gap-1">
                <div className="relative rounded-[50%] bg-darkorchid w-7 h-7 sm:h-5 sm:w-5" />
                <div className="relative leading-[150%]">{translations.graphicPreparation.bleed}</div>
              </div>
              <div className="self-stretch relative text-lg leading-[150%] text-slate-500 lg:text-sm">
              {translations.graphicPreparation.circleNote2}
              </div>
            </div>
            <div className="self-stretch flex-1 flex flex-row items-start justify-start text-base text-slate-900 md:text-sm">
              <div className="self-stretch flex-1 bg-white flex flex-col py-2.5 px-5 items-center justify-center border-[1px] border-solid border-indigo-200 md:px-1">
                <div className="flex flex-row items-start justify-start gap-[3.33px]">
                  <div className="relative leading-[162.5%]">Ø 31</div>
                  <div className="relative leading-[162.5%] text-slate-500 lg:hidden">
                    mm
                  </div>
                </div>
              </div>
              <div className="self-stretch flex-1 bg-white flex flex-col py-2.5 px-5 items-center justify-center border-[1px] border-solid border-indigo-200 md:px-1">
                <div className="flex flex-row items-start justify-start gap-[3.33px]">
                  <div className="relative leading-[162.5%]">Ø 38</div>
                  <div className="relative leading-[162.5%] text-slate-500 lg:hidden">
                    mm
                  </div>
                </div>
              </div>
              <div className="self-stretch flex-1 bg-white flex flex-col py-2.5 px-5 items-center justify-center border-[1px] border-solid border-indigo-200 md:px-1 rounded-br">
                <div className="flex flex-row items-start justify-start gap-[3.33px]">
                  <div className="relative leading-[162.5%]">Ø 56</div>
                  <div className="relative leading-[162.5%] text-slate-500 lg:hidden">
                    mm
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-row items-start justify-start h-24 sm:h-32">
            <div className="self-stretch basis-5/12 bg-white flex flex-col py-3 px-6 items-start justify-center gap-[8px] border-[1px] border-solid border-indigo-200 lg:px-4 sm:px-2">
              <div className="flex flex-row items-center justify-center gap-2.5 sm:gap-1">
                <div className="relative rounded-[50%] bg-mediumorchid w-7 h-7 sm:h-5 sm:w-5" />
                <div className="relative leading-[150%]">{translations.graphicPreparation.cut}</div>
              </div>
              <div className="self-stretch relative text-lg leading-[150%] text-slate-500 lg:text-sm">
              {translations.graphicPreparation.circleNote3}
              </div>
            </div>
            <div className="self-stretch flex-1 flex flex-row items-start justify-start text-base text-slate-900 md:text-sm">
              <div className="self-stretch flex-1 bg-white flex flex-col py-2.5 px-5 items-center justify-center border-[1px] border-solid border-indigo-200 sm:px-1">
                <div className="flex flex-row items-start justify-start gap-[3.33px]">
                  <div className="relative leading-[162.5%]">Ø 35</div>
                  <div className="relative leading-[162.5%] text-slate-500 lg:hidden">
                    mm
                  </div>
                </div>
              </div>
              <div className="self-stretch flex-1 bg-white flex flex-col py-2.5 px-5 items-center justify-center border-[1px] border-solid border-indigo-200 sm:px-1">
                <div className="flex flex-row items-start justify-start gap-[3.33px]">
                  <div className="relative leading-[162.5%]">Ø 43,5</div>
                  <div className="relative leading-[162.5%] text-slate-500 lg:hidden">
                    mm
                  </div>
                </div>
              </div>
              <div className="self-stretch flex-1 bg-white flex flex-col py-2.5 px-5 items-center justify-center border-[1px] border-solid border-indigo-200 sm:px-1">
                <div className="flex flex-row items-start justify-start gap-[3.33px]">
                  <div className="relative leading-[162.5%]">Ø 60,5</div>
                  <div className="relative leading-[162.5%] text-slate-500 lg:hidden">
                    mm
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GraphicPreparation;
