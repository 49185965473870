import React, { FunctionComponent, useState, useEffect, useRef } from "react";
import { Link as RouterLink } from "react-router-dom";
import ReactGA from "react-ga4";
import Cookies from "js-cookie"; // Assuming you're using js-cookie for cookie management

import TableRow from "../ui/custom/tablerow";
import pin from "../../assets/png/pin.png";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { Slider } from "../ui/slider";
import { useTranslation } from "../../TranslationContext";

const Pricing: FunctionComponent = () => {
  const [badgeSize, setBadgeSize] = useState(32);
  const [numberOfPieces, setNumberOfPieces] = useState<number | "">(500);
  const [numberOfGraphics, setNumberOfGraphics] = useState<number | "">(1);
  const [matteBadge, setMatteBadge] = useState(false);
  const [magnetBadge, setMagnetBadge] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [piecePrice, setPiecePrice] = useState(0);
  const translations = useTranslation();

  const logMin = Math.log(30);
  const logMax = Math.log(20000);
  const scale = logMax - logMin; // scale is now the difference between logMax and logMin

  let numberOfPiecesRegex = new RegExp(/^(?:[2-9]\d|[1-9]\d{2,4}|20000)$/);

  useEffect(() => {
    setTotalPrice(0);
    setPiecePrice(0);
    let pricePerPiece = 0;
    if (typeof numberOfPieces === "number") {
      const pricingTable: {
        [key: string]: { min: number; max: number; price: number }[];
      } = {
        "25": [
        { min: 30, max: 49, price: 0.51 },
        { min: 50, max: 99, price: 0.41 },
        { min: 100, max: 499, price: 0.33 },
        { min: 500, max: 999, price: 0.27 },
        { min: 1000, max: 1999, price: 0.24 },
        { min: 2000, max: 4999, price: 0.23 },
        { min: 5000, max: 20000, price: 0.18 },
        { min: 20001, max: 30000, price: 0.17 }, // for 5000 and above
      ],
      "32": [
        { min: 30, max: 49, price: 0.53 },
        { min: 50, max: 99, price: 0.44 },
        { min: 100, max: 499, price: 0.35 },
        { min: 500, max: 999, price: 0.29 },
        { min: 1000, max: 1999, price: 0.26 },
        { min: 2000, max: 4999, price: 0.25 },
        { min: 5000, max: 20000, price: 0.20 },
        { min: 20001, max: 30000, price: 0.19 }, // for 5000 and above
      ],
      "50": [
        { min: 30, max: 49, price: 0.56 },
        { min: 50, max: 99, price: 0.48 },
        { min: 100, max: 499, price: 0.39 },
        { min: 500, max: 999, price: 0.32 },
        { min: 1000, max: 1999, price: 0.29 },
        { min: 2000, max: 4999, price: 0.27 },
        { min: 5000, max: 20000, price: 0.22 },
        { min: 20001, max: 30000, price: 0.21 }, // for 5000 and above
      ],
    }; // Get the pricing array for the selected badge size
      const pricingArray = pricingTable[badgeSize.toString()];

      // Find the two price points that numberOfPieces falls between
      let lowerPricePoint, upperPricePoint;
      for (let i = 0; i < pricingArray.length; i++) {
        if (
          numberOfPieces >= pricingArray[i].min &&
          numberOfPieces <= pricingArray[i].max
        ) {
          lowerPricePoint = pricingArray[i];
          upperPricePoint = pricingArray[i + 1];
          break;
        }
      }

      // Perform linear interpolation between the two price points
      if (lowerPricePoint && upperPricePoint) {
        const range = lowerPricePoint.max - lowerPricePoint.min;
        const progress = lowerPricePoint.max - numberOfPieces;
        const priceDifference = lowerPricePoint.price - upperPricePoint.price;
        pricePerPiece = parseFloat(
          (
            upperPricePoint.price +
            (priceDifference / range) * progress
          ).toFixed(3)
        );
        if (matteBadge) {
          pricePerPiece += 0.02;
        }
        if (magnetBadge) {
          pricePerPiece += 0.45;
        }

        const calculatedTotalPrice =
          parseFloat((pricePerPiece * numberOfPieces).toFixed(2)) +
          (Number(numberOfGraphics) - 1) * 3;
        setTotalPrice(calculatedTotalPrice);
        // Adjusted piecePrice calculation with rounding applied at the end
        const calculatedPiecePrice = calculatedTotalPrice / numberOfPieces;
        setPiecePrice(parseFloat(calculatedPiecePrice.toFixed(3)));
      }
      // Perform calculations
    }
  }, [badgeSize, numberOfPieces, matteBadge, magnetBadge, numberOfGraphics]);

  const defaultBadgeSize = 32; // Default value for badgeSize
  const defaultNumberOfPieces = 500; // Default value for numberOfPieces
  const defaultNumberOfGraphics = 1; // Default value for numberOfGraphics
  const defaultMatteBadge = false; // Default value for matteBadge
  const defaultMagnetBadge = false; // Default value for magnetBadge

  const anyChanged = 
      badgeSize !== defaultBadgeSize ||
      numberOfPieces !== defaultNumberOfPieces ||
      numberOfGraphics !== defaultNumberOfGraphics ||
      matteBadge !== defaultMatteBadge ||
      magnetBadge !== defaultMagnetBadge;

  const params = new URLSearchParams();
  params.append('badgeSize', badgeSize.toString());
  params.append('numberOfPieces', numberOfPieces.toString());
  params.append('numberOfGraphics', numberOfGraphics.toString());
  params.append('matteBadge', matteBadge.toString());
  params.append('magnetBadge', magnetBadge.toString());

  const orderLink = `/order?${params.toString()}`;

  return (
    <div
      id="pricing"
      className="relative bg-white w-full flex flex-col py-20 px-20 box-border items-center justify-start text-center text-4xl text-slate-900 font-text-lg-font-normal md:px-14 sm:py-14 xs:px-4 xs:text-3xl"
    >
      <div className="w-full flex flex-col items-center justify-start gap-[48px] max-w-[960px] pb-10">
        <div className="self-stretch flex flex-col items-center justify-start gap-[24px]">
          <div className="self-stretch flex flex-col items-center justify-start gap-[16px]">
            <div className="self-stretch relative tracking-[-0.01em] leading-[125%] font-extrabold">
              <h1>{translations.pricing.bestPrices}</h1>
            </div>
            <div className="self-stretch relative text-xl leading-[150%] text-slate-500 sm:text-lg">
              {translations.pricing.description}{" "}
              <span className="text-slate-700 font-semibold">
                {translations.pricing.minimumOrder}
              </span>
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-col items-center justify-center gap-[16px] text-center text-base w-full">
          <div className="self-stretch overflow-hidden flex flex-col items-center justify-start border-solid border-indigo-200 rounded-md border-[1px]">
            <div className="self-stretch h-36 flex flex-row items-start justify-start sm:h-28">
              <div className="self-stretch flex-1 bg-white flex flex-col p-1 items-center justify-center text-center text-lg border-solid border-indigo-200 rounded-tl border-[1px]">
                <div className="self-stretch flex flex-row items-start justify-start">
                  <div className="flex-1 relative leading-[162.5%] font-medium lg:text-base sm:text-sm">
                    {translations.pricing.piecesSize}
                  </div>
                </div>
              </div>
              <div className="self-stretch flex-1 bg-white flex flex-col p-1 items-center justify-center relative gap-[3.33px] border-[1px] border-solid border-indigo-200">
                <img
                  className="relative w-4/12 object-cover z-[0] lg:w-5/12 md:w-6/12 sm:w-7/12 xs:w-9/12"
                  alt={translations.pricing.badge25Alt}
                  src={pin}
                />
                <div className="my-0 mx-[!important] absolute top-[calc(50%_-_14.08px)] left-[calc(50%_-_18.18px)] flex flex-row items-start justify-start z-[1]">
                  <div className="relative leading-[162.5%] font-bold  text-gradient">
                    Ø 25
                  </div>
                </div>
              </div>
              <div className="self-stretch flex-1 bg-white flex flex-col p-1 items-center justify-center relative gap-[3.33px] border-[1px] border-solid border-indigo-200">
                <img
                  className="relative w-5/12 object-cover z-[0] lg:w-6/12 md:w-8/12 sm:w-9/12 xs:w-10/12"
                  alt={translations.pricing.badge32Alt}
                  src={pin}
                />
                <div className="my-0 mx-[!important] absolute top-[calc(50%_-_14.08px)] left-[calc(50%_-_18.18px)] flex flex-row items-start justify-start z-[1]">
                  <div className="relative leading-[162.5%] font-bold  text-gradient">
                    Ø 32
                  </div>
                </div>
              </div>
              <div className="self-stretch flex-1 bg-white flex flex-col p-1 items-center justify-center relative gap-[3.33px] border-[1px] border-solid border-indigo-200 rounded-tr">
                <img
                  className="relative w-6/12 object-cover z-[0] lg:w-8/12 md:w-10/12 sm:w-11/12 xs:w-full"
                  alt={translations.pricing.badge50Alt}
                  src={pin}
                />
                <div className="my-0 mx-[!important] absolute top-[calc(50%_-_14.08px)] left-[calc(50%_-_19.18px)] flex flex-row items-start justify-start z-[1]">
                  <div className="relative leading-[162.5%] font-bold  text-gradient">
                    Ø 50
                  </div>
                </div>
              </div>
            </div>
            <TableRow
              columns={4}
              data={[
                { value: "30", unit: "ks" },
                { value: "0,51", unit: "€" },
                { value: "0,53", unit: "€" },
                { value: "0,56", unit: "€" },
              ]}
            />
            <TableRow
              columns={4}
              data={[
                { value: "50", unit: "ks" },
                { value: "0,41", unit: "€" },
                { value: "0,44", unit: "€" },
                { value: "0,48", unit: "€" },
              ]}
            />
            <TableRow
              columns={4}
              data={[
                { value: "100", unit: "ks" },
                { value: "0,33", unit: "€" },
                { value: "0,35", unit: "€" },
                { value: "0,39", unit: "€" },
              ]}
            />
            <TableRow
              columns={4}
              data={[
                { value: "500", unit: "ks" },
                { value: "0,27", unit: "€" },
                { value: "0,29", unit: "€" },
                { value: "0,32", unit: "€" },
              ]}
            />
            <TableRow
              columns={4}
              data={[
                { value: "1000", unit: "ks" },
                { value: "0,24", unit: "€" },
                { value: "0,26", unit: "€" },
                { value: "0,29", unit: "€" },
              ]}
            />
            <TableRow
              columns={4}
              data={[
                { value: "2000", unit: "ks" },
                { value: "0,23", unit: "€" },
                { value: "0,25", unit: "€" },
                { value: "0,27", unit: "€" },
              ]}
            />
            <TableRow
              columns={4}
              data={[
                { value: "5000", unit: "ks" },
                { value: "0,18", unit: "€" },
                { value: "0,20", unit: "€" },
                { value: "0,22", unit: "€" },
              ]}
            />
          </div>
          <div className="self-stretch relative font-normal text-lg leading-[150%] text-slate-500 text-center sm:text-sm">
            <div>{translations.pricing.pricesExclVAT}</div>
            <div>
              {translations.pricing.matteSurcharge}{" "}
              <span className="text-slate-600 font-semibold">0,02€</span>.
            </div>
            {translations.pricing.magnetSurcharge}{" "}
            <span className="text-slate-600 font-semibold">0,45€</span>.
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col items-center justify-center gap-[48px] max-w-[1080px] pt-6">
        <div className="self-stretch flex flex-col items-center justify-center">
          <div className="self-stretch relative leading-[150%] font-extrabold xs:text-3xl">
            <h1>{translations.pricing.calculatePrice}</h1>
          </div>
        </div>
        <div className="relative self-stretch flex flex-row lg:flex-col items-center justify-center gap-20 lg:gap-16 md:gap-12 text-xl text-slate-500">
          <div className="flex flex-col items-center justify-center py-6 px-0 gap-10 lg:gap-6 w-full max-w-[550px] xs:max-w-[350px]">
            <div className="self-stretch flex flex-row items-start justify-start gap-[16px]">
              <b className="relative leading-[150%] text-gradient">1.</b>
              <div className="flex-1 shrink-0 flex flex-row items-center justify-between text-base xs:text-sm text-slate-600 xxs:flex-col xxs:items-start xxs:gap-2">
                <div className="shrink-0 flex flex-col items-start justify-start">
                  <div className="relative leading-[150%] font-medium">
                    {translations.pricing.selectSize}
                  </div>
                  <div className="relative text-sm xs:text-xs leading-[150%] text-slate-500">
                    {translations.pricing.diameterInMM}
                  </div>
                </div>
                <div className="shrink-0 flex flex-row items-start justify-start gap-[8px] text-sm text-slate-500">
                  <Button
                    variant={badgeSize === 25 ? "outline" : "outline_ghost"}
                    size="sm"
                    disabled={true}
                    onClick={() => setBadgeSize(25)}
                    className="px-6 md:px-4 xs:px-2"
                  >
                    Ø 25
                  </Button>
                  <Button
                    variant={badgeSize === 32 ? "outline" : "outline_ghost"}
                    size="sm"
                    onClick={() => setBadgeSize(32)}
                    className="px-6 md:px-4 xs:px-2"
                  >
                    Ø 32
                  </Button>
                  <Button
                    variant={badgeSize === 50 ? "outline" : "outline_ghost"}
                    size="sm"
                    onClick={() => setBadgeSize(50)}
                    className="px-6 md:px-4 xs:px-2"
                  >
                    Ø 50
                  </Button>
                </div>
              </div>
            </div>
            <div className="self-stretch h-[109px] flex flex-row items-start justify-start gap-[16px]">
              <b className="relative leading-[150%] text-gradient">2.</b>
              <div className="flex-1 flex flex-col items-start justify-start gap-[24px] text-base xs:text-sm text-slate-600">
                <div className="self-stretch flex flex-row items-center justify-between">
                  <div className="shrink-0 flex flex-col items-start justify-start">
                    <div className="relative leading-[150%] font-medium">
                      {translations.pricing.numberOfPieces}
                    </div>
                    <div className="relative text-sm xs:text-xs leading-[150%] text-slate-500">
                      {translations.pricing.minimumIs30}
                    </div>
                  </div>
                  <Input
                    type="number"
                    placeholder="0"
                    centered={true}
                    className="w-[80px] h-[48px] px-2 bg-white border-slate-400 font-medium"
                    onWheel={(e) => {
                      e.currentTarget.blur();
                    }}
                    onValueChange={(value) => {
                      if (value === "") {
                        setNumberOfPieces("");
                      } else {
                        const numValue = Number(value);
                        if (numValue <= 20000) {
                          setNumberOfPieces(numValue);
                        }
                      }
                    }}
                    onBlur={() => {
                      // This function is called when the user stops dragging the slider
                      const cookieValue = Cookies.get("cookies-preference");
                      let analyticsConsent = false;

                      if (cookieValue) {
                        try {
                          const preferences = JSON.parse(cookieValue);
                          analyticsConsent = preferences.analytical === true; // Note the strict equality to boolean true
                        } catch (error) {
                          console.error(
                            "Error parsing cookies-preference:",
                            error
                          );
                        }
                      }
                      if (analyticsConsent) {
                        const analyticsValue =
                          typeof numberOfPieces === "number"
                            ? numberOfPieces
                            : 0;
                        ReactGA.event({
                          category: "Pricing Calculator",
                          action: "Text Input Blur",
                          label: "NumberOfPieces",
                          value: analyticsValue, // Assuming numberOfPieces is the value you want to track
                          nonInteraction: false, // Since this is a user interaction
                          transport: "xhr",
                        });
                      }
                    }}
                    value={numberOfPieces}
                    showCloseIcon={false}
                    min={30}
                    max={20000}
                  />
                </div>
                <div className="self-stretch flex flex-col items-center justify-center gap-[12px] text-sm text-slate-500">
                  <div className="self-stretch relative rounded-xl bg-slate-200 h-2">
                    <Slider
                      min={0}
                      max={1}
                      step={0.01}
                      value={[
                        typeof numberOfPieces === "number"
                          ? (Math.log(numberOfPieces) - logMin) /
                            (logMax - logMin)
                          : 0,
                      ]}
                      onValueChange={(values) => {
                        const val = Math.exp(logMin + scale * values[0]);
                        setNumberOfPieces(Math.round(val));
                      }}
                      onValueCommit={() => {
                        // This function is called when the user stops dragging the slider
                        const cookieValue = Cookies.get("cookies-preference");
                        let analyticsConsent = false;

                        if (cookieValue) {
                          try {
                            const preferences = JSON.parse(cookieValue);
                            analyticsConsent = preferences.analytical === true; // Note the strict equality to boolean true
                          } catch (error) {
                            console.error(
                              "Error parsing cookies-preference:",
                              error
                            );
                          }
                        }
                        if (analyticsConsent) {
                          const analyticsValue =
                            typeof numberOfPieces === "number"
                              ? numberOfPieces
                              : 0;
                          ReactGA.event({
                            category: "Pricing Calculator",
                            action: "Slider Released",
                            label: "NumberOfPieces",
                            value: analyticsValue, // Assuming numberOfPieces is the value you want to track
                            nonInteraction: false, // Since this is a user interaction
                            transport: "xhr",
                          });
                        }
                      }}
                    />
                  </div>
                  <div className="self-stretch flex flex-row items-start justify-between">
                    <div className="relative leading-[150%]">30</div>
                    <div className="relative leading-[150%]">500</div>
                    <div className="relative leading-[150%]">20000</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start gap-[16px] text-slate-600">
              <b className="relative leading-[150%] text-gradient">3.</b>
              <div className="flex-1 shrink-0 flex flex-row items-center justify-between text-base xs:text-sm">
                <div className="shrink-0 flex flex-col items-start justify-start">
                  <div className="relative leading-[150%] font-medium">
                    {translations.pricing.matteVersion}
                  </div>
                  <div className="relative text-sm xs:text-xs leading-[150%] text-slate-500">
                    +0.02€/ks
                  </div>
                </div>
                <div className="shrink-0 flex flex-row items-start justify-start gap-[8px] text-sm text-slate-500">
                  <Button
                    variant={matteBadge ? "outline" : "outline_ghost"}
                    size="sm"
                    className="w-[80px]"
                    onClick={() => setMatteBadge(true)}
                  >
                    {translations.pricing.yes}
                  </Button>
                  <Button
                    variant={!matteBadge ? "outline" : "outline_ghost"}
                    size="sm"
                    className="w-[80px]"
                    onClick={() => setMatteBadge(false)}
                  >
                    {translations.pricing.no}
                  </Button>
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start gap-[16px] text-slate-600">
              <b className="relative leading-[150%] text-gradient">4.</b>
              <div className="flex-1 shrink-0 flex flex-row items-center justify-between text-base xs:text-sm">
                <div className="shrink-0 flex flex-col items-start justify-start">
                  <div className="relative leading-[150%] font-medium">
                    {translations.pricing.magneticVersion}
                  </div>
                  <div className="relative text-sm xs:text-xs leading-[150%] text-slate-500">
                    +0.45€/ks
                  </div>
                </div>
                <div className="shrink-0 flex flex-row items-start justify-start gap-[8px] text-sm text-slate-500">
                  <Button
                    variant={magnetBadge ? "outline" : "outline_ghost"}
                    size="sm"
                    className="w-[80px]"
                    onClick={() => setMagnetBadge(true)}
                  >
                    {translations.pricing.yes}
                  </Button>
                  <Button
                    variant={!magnetBadge ? "outline" : "outline_ghost"}
                    size="sm"
                    className="w-[80px]"
                    onClick={() => setMagnetBadge(false)}
                  >
                    {translations.pricing.no}
                  </Button>
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start gap-[16px] text-slate-600">
              <b className="relative leading-[150%] text-gradient">5.</b>
              <div className="flex-1 shrink-0 flex flex-row items-center justify-between text-base xs:text-sm xxs:flex-col xxs:items-start xxs:gap-2">
                <div className="shrink-0 flex flex-col items-start justify-start">
                  <div className="relative leading-[150%] font-medium">
                    {translations.pricing.differentDesigns}
                  </div>
                  <div className="relative text-sm xs:text-xs leading-[150%] text-slate-500">
                    {translations.pricing.differentDesignsPrice}
                  </div>
                </div>
                <div className="shrink-0 flex flex-row items-start justify-start gap-[8px] text-sm text-slate-500">
                  <Button
                    variant="outline_ghost"
                    size="sm"
                    className="w-[40px] text-4xl font-light pt-1.5 pb-2.5"
                    onClick={() =>
                      setNumberOfGraphics((prev) =>
                        Math.max(1, (prev === "" ? 0 : prev) - 1)
                      )
                    }
                  >
                    -
                  </Button>
                  <Input
                    type="number"
                    placeholder="0"
                    centered={true}
                    className="w-[50px] h-[48px] px-2 bg-white border-slate-400 font-medium"
                    onWheel={(e) => {
                      e.currentTarget.blur();
                    }}
                    onValueChange={(value) => {
                      if (value === "") {
                        setNumberOfGraphics("");
                      } else {
                        const numValue = Number(value);
                        if (numValue <= 20) {
                          setNumberOfGraphics(numValue);
                        }
                      }
                    }}
                    value={numberOfGraphics}
                    showCloseIcon={false}
                    min={1}
                    max={20}
                  />
                  <Button
                    variant="outline_ghost"
                    size="sm"
                    className="w-[40px] text-4xl font-light sm:px-1"
                    onClick={() =>
                      setNumberOfGraphics((prev) =>
                        Math.min(30, (prev === "" ? 0 : prev) + 1)
                      )
                    }
                  >
                    +
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="rounded-2xl bg-white overflow-hidden flex flex-col items-center justify-center py-8 px-16 gap-5 text-base text-slate-600 border-[2px] border-solid border-fuchsia-500 min-w-[370px] sm:min-w-[250px] max-w-[450px]">
            <div className="relative leading-[150%] uppercase font-medium">
              {translations.pricing.finalPrice}
            </div>
            <div className="shrink-0 flex flex-col items-center justify-center text-5xl text-slate-500">
              <div className="shrink-0 flex flex-col items-center   justify-start">
                <b className="relative leading-[150%] text-gradient">
                  {totalPrice.toFixed(2)}€
                </b>
                <div className="self-stretch flex flex-row items-center justify-center text-lg text-slate-600">
                  <div className="relative leading-[150%]">
                    {piecePrice.toFixed(3)}€/ks
                  </div>
                </div>
              </div>
              <div className="relative text-base leading-[150%]">
                {translations.pricing.exclVATAndShipping}
              </div>
            </div>
            <div className="shrink-0 flex flex-col items-center justify-center text-white pt-5">
              <RouterLink to={anyChanged ? orderLink : '/order'}>
                <Button
                  variant="default"
                  size="xl"
                  className="uppercase w-[180px]"
                  onClick={() => window.scrollTo(0, 0)}
                >
                  {translations.pricing.order}
                </Button>
              </RouterLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
